import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

const IndexPage = () => {
  return (
    <main className="flex h-screen">
      <Helmet>
        <title>New Age Myanmar Cultural Foundation</title>
      </Helmet>
      <div className="m-auto">
        <div className="mx-10 mb-3">
          <StaticImage
            src="../images/logo.png"
            alt="New Age Myanmar Cultural Foundation"
          />
        </div>
        <div className="flex flex-col items-center justify-center space-y-4">
          <h1 className="text-4xl antialiased tracking-wide text-gray-700 font-pacifico md:text-5xl">
            We're coming soon
          </h1>
          <h3 className="text-2xl antialiased tracking-wide text-gray-700 font-pacifico">
            Thanks for your interest
          </h3>
          <p className="mx-10 text-4xl antialiased text-center text-gray-500 font-caveat">
            You can{' '}
            <a href="https://www.facebook.com/Newagemyanmar-Cultural-Foundation-110941948130765">
              <i className="text-blue-500 border-b border-gray-600 border-dashed hover:not-italic">
                follow
              </i>
            </a>{' '}
            us on{' '}
            <a
              href="https://www.facebook.com/Newagemyanmar-Cultural-Foundation-110941948130765"
              className="text-blue-500 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="inline-flex border-2 border-blue-600 rounded-md w-7 h-7 fill-blue-500"
              >
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
              </svg>
              <i className="border-b border-gray-600 border-dashed hover:not-italic">
                facebook
              </i>
            </a>{' '}
            for further updates
          </p>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
